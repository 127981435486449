/**
 * Created by osirvent on 09/11/2015.
 */
angular
    .module('annexaApp')
    .config(['$httpProvider','$translateProvider', 'tmhDynamicLocaleProvider', '$animateProvider', function ($httpProvider, $translateProvider, tmhDynamicLocaleProvider, $animateProvider) {
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.translations('es',{
            DIALOGS_ERROR: "Error",
            DIALOGS_ERROR_MSG: "Se ha producido un error.",
            DIALOGS_CLOSE: "Cerrar",
            DIALOGS_PLEASE_WAIT: "Espere por favor",
            DIALOGS_PLEASE_WAIT_ELIPS: "Espere por favor...",
            DIALOGS_PLEASE_WAIT_MSG: "Completando operación.",
            DIALOGS_PERCENT_COMPLETE: "% Completado",
            DIALOGS_NOTIFICATION: "Notificación",
            DIALOGS_NOTIFICATION_MSG: "Notificación de una aplicación desconocida.",
            DIALOGS_CONFIRMATION: "Confirmación",
            DIALOGS_CONFIRMATION_MSG: "Se requiere confirmación.",
            DIALOGS_OK: "Aceptar",
            DIALOGS_YES: "Sí",
            DIALOGS_NO: "No"
        });


        $translateProvider.preferredLanguage('es');
        $translateProvider.useLocalStorage();
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('libs/angular/angular-i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
        
        $httpProvider.interceptors.push('httpRequestInterceptor');

        $animateProvider.classNameFilter(/animate/);
    }])
    .config(['$urlMatcherFactoryProvider', function($urlMatcherFactory) {
        $urlMatcherFactory.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val == true ? true : val == "true" ? true : false },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0 },
            pattern: /bool|true|0|1/
        });
    }])
    .config(['$compileProvider', function($compileProvider) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|ms-word|ms-powerpoint|ms-excel):/)
    }])
    .config(['formlyConfigProvider', function (formlyConfigProvider) {
        function camelize(string) {
            string = string.replace(/[\-_\s]+(.)?/g, function(match, chr) {
                return chr ? chr.toUpperCase() : '';
            });
            // Ensure 1st char is always lowercase
            return string.replace(/^([A-Z])/, function(match, chr) {
                return chr ? chr.toLowerCase() : '';
            });
        }

        var attributes = [
            'date-disabled',
            'custom-class',
            'show-weeks',
            'starting-day',
            'init-date',
            'min-mode',
            'max-mode',
            'format-day',
            'format-month',
            'format-year',
            'format-day-header',
            'format-day-title',
            'format-month-title',
            'year-range',
            'shortcut-propagation',
            'datepicker-options',
            'show-button-bar',
            'current-text',
            'clear-text',
            'close-text',
            'close-on-date-selection',
            'datepicker-append-to-body'
        ];

        var bindings = [
            'datepicker-mode',
            'min-date',
            'max-date'
        ];

        var ngModelAttrs = {};

        angular.forEach(attributes, function(attr) {
            ngModelAttrs[camelize(attr)] = {attribute: attr};
        });

        angular.forEach(bindings, function(binding) {
            ngModelAttrs[camelize(binding)] = {bound: binding};
        });

        formlyConfigProvider.setType({
            name: 'annexaDatepickerRow',
            templateUrl:  './views/templates/formly/annexadatepickerrow.html',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                ngModelAttrs: ngModelAttrs,
                templateOptions: {
                    datepickerOptions: {
                        format: 'dd/MM/yyyy',
                        initDate: new Date(),
                        showWeeks: false,
                        startingDay: 1
                    }
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.datepicker = {};

                $scope.datepicker.opened = false;

                $scope.datepicker.open = function ($event) {
                    $scope.datepicker.opened = !$scope.datepicker.opened;
                };
            }]
        });
        
        formlyConfigProvider.setType({
            name: 'annexaDatepickerMaskRow',
            templateUrl:  './views/templates/formly/annexadatepickermaskrow.html',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                ngModelAttrs: ngModelAttrs,
                templateOptions: {
                    datepickerOptions: {
                        format: 'dd/MM/yyyy',
                        initDate: new Date(),
                        showWeeks: false,
                        startingDay: 1
                    }
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.datepicker = {};

                $scope.datepicker.opened = false;

                $scope.datepicker.open = function ($event) {
                    $scope.datepicker.opened = !$scope.datepicker.opened;
                };
            }]
        });

        formlyConfigProvider.setType({
            name: 'annexaDatepicker',
            templateUrl:  './views/templates/formly/annexadatepicker.html',
            wrapper: ['bootstrapHasError'],
            defaultOptions: {
                ngModelAttrs: ngModelAttrs,
                templateOptions: {
                    datepickerOptions: {
                        format: 'dd/MM/yyyy',
                        initDate: new Date(),
                        showWeeks: false,
                        startingDay: 1
                    }
                }
            },
            controller: ['$scope', function ($scope) {
                $scope.datepicker = {};

                $scope.datepicker.opened = false;

                $scope.datepicker.open = function ($event) {
                    $scope.datepicker.opened = !$scope.datepicker.opened;
                };
            }]
        });

        formlyConfigProvider.setType({
            name: 'annexaTimepickerRow',
            templateUrl: './views/templates/formly/annexatimepickerrow.html',
            wrapper: ['bootstrapHasError']
        });

        formlyConfigProvider.setType({
            name: 'annexaImageUpload',
            templateUrl: './views/templates/formly/annexaImageUpload.html',
            wrapper: ['bootstrapHasError']
        })
        formlyConfigProvider.setType({
            name: 'annexaFileUpload',
            templateUrl: './views/templates/formly/annexafileupload.html',
            wrapper: ['bootstrapHasError']
        })
        formlyConfigProvider.setType({
            name: 'annexaMultiFileUpload',
            templateUrl: './views/templates/formly/annexamultifileupload.html',
            wrapper: ['bootstrapHasError']
        })
        formlyConfigProvider.setType({
            name: 'annexaUpload',
            templateUrl: './views/templates/formly/annexaupload.html',
            wrapper: ['bootstrapHasError']
        })
        formlyConfigProvider.setType({
            name: 'annexaInput',
            templateUrl: './views/templates/formly/annexainput.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaInputLanguage',
            templateUrl: './views/templates/formly/annexainputlanguage.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaTextAreaLanguage',
            templateUrl: './views/templates/formly/annexatextarealanguage.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaTextAreaLanguageCustomFields',
            templateUrl: './views/templates/formly/annexatextarealanguagecustomfields.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaTextareaLanguageNoMultilanguage',
            templateUrl: './views/templates/formly/annexatextarealanguagenomultilanguage.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaDocumentFileTemplate',
            templateUrl: './views/templates/formly/annexadocumentfiletemplate.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaInputRow',
            templateUrl: './views/templates/formly/annexainputrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSummernoteRow',
            templateUrl: './views/templates/formly/annexasummernoterow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaHidden',
            templateUrl: './views/templates/formly/annexaHidden.html',
        });
        formlyConfigProvider.setType({
            name: 'annexaTypeaheadRow',
            templateUrl: './views/templates/formly/annexatypeaheadrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaFilterInputRow',
            templateUrl: './views/templates/formly/annexafilterinputrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaTypeaheadFieldSet',
            templateUrl: './views/templates/formly/annexatypeaheadfieldset.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaLoadUserRow',
            templateUrl: './views/templates/formly/annexaloaduserrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaCheckbox',
            templateUrl: './views/templates/formly/annexacheckbox.html',
            wrapper: ['bootstrapHasError']

        });
        formlyConfigProvider.setType({
            name: 'annexaTextArea',
            templateUrl: './views/templates/formly/annexatextarea.html',
            wrapper: ['bootstrapHasError']

        });
        formlyConfigProvider.setType({
            name: 'annexaTextAreaRow',
            templateUrl: './views/templates/formly/annexatextarearow.html',
            wrapper: ['bootstrapHasError']

        });
        formlyConfigProvider.setType({
            name: 'annexaTitle',
            templateUrl: './views/templates/formly/annexatitle.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaDocumentsByTypeConfiguration',
            templateUrl: './views/templates/formly/annexadocumentbytypeconfiguration.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLabel',
            templateUrl: './views/templates/formly/annexalabel.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLabelRow',
            templateUrl: './views/templates/formly/annexalabelrow.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLabelsRow',
            templateUrl: './views/templates/formly/annexalabelsrow.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLabelRowEdit',
            templateUrl: './views/templates/formly/annexalabelrowedit.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLabelButton',
            templateUrl: './views/templates/formly/annexalabelbutton.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLoginInput',
            templateUrl: './views/templates/formly/annexalogininput.html'
        });

        formlyConfigProvider.setType({
            name: 'annexaSignConflict',
            templateUrl: './views/templates/formly/annexasignconflict.html'
        });

        formlyConfigProvider.setType({
            name: 'annexaCertificates',
            templateUrl: './views/templates/formly/annexacertificates.html'
        });

        formlyConfigProvider.setType({
            name: 'annexaMultipleSelect',
            extends: 'select',
            templateUrl: './views/templates/formly/annexamultipleselect.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaMultipleSelectRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexamultipleselectrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaMultipleSelectRowHtml',
            extends: 'select',
            templateUrl: './views/templates/formly/annexamultipleselectrowHtml.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexaselectrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectResponsibleRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexaselectresponsiblerow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectGroupRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexaselectgrouprow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaTrueFalse',
            templateUrl: './views/templates/formly/annexatruefalse.html',
            wrapper: ['bootstrapHasError']
        })
        formlyConfigProvider.setType({
            name: 'annexaRENROP',
            templateUrl: './views/templates/formly/annexarenrop.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectTreeRow',
            templateUrl: './views/templates/formly/annexaselecttreerow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectSearchButtonRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexaselectsearchbuttonrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaRadioCheckboxRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexaradiocheckboxrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaRadioRow',
            extends: 'select',
            templateUrl: './views/templates/formly/annexaradiorow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaLanguageFieldSet',
            templateUrl: './views/templates/formly/annexalanguagefieldset.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaComponent',
            templateUrl: './views/templates/formly/annexacomponent.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectFieldSet',
            templateUrl: './views/templates/formly/annexaselectfieldset.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaCustomFields',
            templateUrl: './views/templates/formly/annexacustomfields.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaPresetSignActions',
            templateUrl: './views/templates/formly/annexapresetsignactions.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaDivFileUpload',
            templateUrl: './views/templates/formly/annexadivfileupload.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaErrorsSubmit',
            templateUrl: './views/templates/formly/annexaerrorssubmit.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaFilterRow',
            templateUrl: './views/templates/formly/annexafilterrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSelectAllRow',
            templateUrl: './views/templates/formly/annexaselectallrow.html',
            wrapper: ['bootstrapHasError']
        });
        formlyConfigProvider.setType({
            name: 'annexaSaveCommentsToDocument',
            templateUrl: './views/templates/formly/annexasavecommentstodocument.html'
        });
        formlyConfigProvider.setWrapper({
            name: 'annexaRow',
            templateUrl: './views/templates/formly/annexarow.html'
        });
        formlyConfigProvider.setType({
            name: 'annexaLoadDecreeRow',
            templateUrl: './views/templates/formly/annexaloaddecreerow.html',
            wrapper: ['bootstrapHasError']
        });

        formlyConfigProvider.setWrapper({
            name: 'validation',
            types: ['annexaInput', 'annexaTextArea', 'annexaLabel', 'annexaLabelButton', 'annexaLoginInput'],
            templateUrl: './views/templates/formly/validation.html'
        });
        formlyConfigProvider.setWrapper({
            name: 'validationRow',
            types: ['annexaInputRow', 'annexaSelectRow', 'annexaSelectResponsibleRow','annexaSelectTreeRow', 'annexaTextAreaRow', 'input', 'annexaMultipleSelectRow', 'annexaRadioCheckboxRow', 'annexaDatepickerRow', 'annexaDatepickerMaskRow', 'annexaDatepicker', 'annexaInputLanguage', 'annexaLoadUserRow', 'annexaTypeaheadRow', 'annexaFilterInputRow', 'annexaFileUpload', 'annexaMultiFileUpload', 'annexaFileUploadBase64', 'annexaUpload','annexaCustomFields','annexaPresetSignActions', 'annexaDivFileUpload', 'annexaImageUpload','annexaDocumentFileTemplate', 'annexafilterrow','annexaCheckbox', 'annexaSelectSearchButtonRow','annexaMultipleSelectRowHtml', 'annexaSummernoteRow', 'annexaRENROP', 'annexaTrueFalse', 'annexaTimepickerRow', 'annexaSelectAllRow', 'annexaSelectGroupRow', 'annexaLoadDecreeRow'],
            templateUrl: './views/templates/formly/validationrow.html'
        });
        formlyConfigProvider.setWrapper({
            name: 'fileSetWrapper',
            types: ['annexaLanguageFieldSet', 'annexaSelectFieldSet', 'annexaTypeaheadFieldSet'],
            templateUrl: './views/templates/formly/fieldsetwrapper.html'
        });
        formlyConfigProvider.setWrapper({
            name: 'accordionWrapper',
            templateUrl: './views/templates/formly/accordionwrapper.html'
        })
        formlyConfigProvider.setWrapper({
            name: 'boxWrapper',
            types: [],
            templateUrl: './views/templates/formly/boxwrapper.html'
        });
    }]);
